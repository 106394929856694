import React from "react";
import "../css/categories.css";
import { importAllImages } from "../Helper";
import { Link } from "react-router-dom";

export default function Categories(props) {
  const uid = props.uid;

  const images = importAllImages(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );
  return (
    <>
      <div className="category-header">
        <div className="category-title">
          <h4>Explore Categories</h4>
        </div>
      </div>
      <div className="categories-container">
        <div className="grid">
          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=Savings Account&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img src={images["home.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Savings Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=Demat Account&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img src={images["demat.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Demat Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=Current Account&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img src={images["current_account.png"]} alt="" className="categoryImage" />
              <p className="categoryName">Current Account</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=Credit Card&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img
                src={images["credit-card.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">Credit Card</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=BNPL&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img
                src={images["pay-later.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">BNPL</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts/Loans",
              search: "?category=Loan&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img
                src={images["instant_loan.png"]}
                alt=""
                className="categoryImage"
              />
              <p className="categoryName">Loan</p>
            </div>
          </Link>

          <Link
            className="linkClass"
            to={{
              pathname: "/AllProducts",
              search: "?category=UPI&uid=" + uid,
            }}
          >
            <div className="grid-item">
              <img src={images["upi.png"]} alt="" className="categoryImage" />
              <p className="categoryName">UPI</p>
            </div>
          </Link>
        </div>
      </div>{" "}
    </>
  );
}
