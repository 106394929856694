export  function importAllImages(r) {
    let images = {};
    r.keys().map((item, index) => { 
        images[item.replace('./', '')] = r(item);
        // return true;
    });
    return images;
}


