import React from 'react'
import sad from '../images/sad.png'

export default function PayUFailure() {
    return (
        <div className="payudiv" style={{ position: 'absolute',
        width: '90%',
        height: '20%',
        left: '15%',
        top: '45%',
       }}>
            <img src={sad} alt="" id="payulogo" style={{position: 'absolute', left: '40%', width: '20%', height: 'auto', }}/>
            <p className="tagline" id="taglineId" style={{ position: 'absolute', top: '60%', height: '14px', right: '10%'}}>
                Payment Failed! Please wait we are redirecting you to ARYO App, Do not refresh</p>
        </div>
      )
}
