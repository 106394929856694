import React, { useEffect, useState } from "react";
import { getLiveProjects, checkUID } from "../Connection";
import { useSearchParams, Link } from "react-router-dom";
import * as States from "./States";
import InvalidUrl from "./InvalidUrl";
import Error from "./Error";
import "../css/allproducts.css";
import ProductsShimmer from "./ProductsShimmer";
import tag from "../images/tag.png";

export default function AllProducts() {
  const [params] = useSearchParams();
  const category = params.get("category");
  const uid = params.get("uid");
  const [listProdcuts, setListProdcuts] = useState([]);
  const [state, setState] = useState(null);

  useEffect(() => {
    checkUID(uid, (response) => {
      if (response) {
        getLiveProjects(category, (res) => {
          if (res.status === States.SUCCESS) {
            setListProdcuts(res.result);
            setState(States.SUCCESS);
          } else {
            setState(States.ERROR);
          }
        });
      } else {
        setState(States.INVALIDURL);
      }
    });
  }, [category, uid]);

  if (state === States.ERROR) {
    return (
      <>
        <Error />
      </>
    );
  } else if (state === States.INVALIDURL) {
    return (
      <>
        <InvalidUrl />
      </>
    );
  } else if (state === States.SUCCESS) {
    return (
      <div className="AProd-root">
        <p className="category-heading-allProduct">Top {category}s</p>
        <div className="Products">
          {listProdcuts.map((project, index) => (
            <Link
              key={index}
              to={{
                pathname: "/",
                search: `?category=${
                  project.name === "Tide" ? "Business Account" : category
                }&project=${project.name}&uid=${uid}`,
              }}
              className="linkPath"
            >
              <div className="mainbox-page">
                <div className="product-container">
                  <div className="product-inner">
                    <div className="project-logo">
                      <img src={project.logo} alt="" className="logo" />
                    </div>
                    <div className="project-heading">{project.heading}</div>
                  </div>
                  <div className="usp-box">
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={tag} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp1}</p>
                      </div>
                    </div>
                    <div className="usp-box-inner">
                      <div className="usp-image">
                        <img src={tag} alt="" />
                      </div>
                      <div className="usp-info">
                        <p> {project.usp2}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="AProd-root">
          <p className="category-heading-allProduct">Top {category}s</p>
          <ProductsShimmer />
          <ProductsShimmer />
          <ProductsShimmer />
          <ProductsShimmer />
          <ProductsShimmer />
          <ProductsShimmer />
          <ProductsShimmer />
        </div>
      </>
    );
  }
}
