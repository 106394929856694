import React, { useState, useEffect } from "react";
import {
  postLead,
  getProjectDetails,
  getProjectDetailsByPath,
  checkUID,
} from "./Connection";
import Alert from "./Components/Alert";
import { useSearchParams, useNavigate } from "react-router-dom";
import { OnHoldUI } from "./Components/OnHoldUI";
import InvalidUrl from "./Components/InvalidUrl";
import LeadForm from "./Components/LeadForm";
import { setCustlead } from "./Components/Helper";
import { LEAD_CATEGORIES } from "./Components/AllLeadCategories";
import Spinner from "./Components/Spinner";
import * as States from "./Components/States";
function App() {

  console.log("==APP==")
  const projectElements = {
    projectName: "",
    leadCategory: "",
    bannerUrl: "",
    projectDetails: "",
  };

  const [projectData, setProjectData] = useState(projectElements);

  const [state, setState] = useState(null);
  const [link, setLink] = useState("");
  const [lead, setLead] = useState({});

  var uid = "";
  var isFromWebsite = false;
  const navigation = useNavigate();
  const [params] = useSearchParams();
  // console.log(params)
  const hash = params.get("uid").split("=")[0];
  const brandName = params.get("project");
  const brandCategory = params.get("category");
  
  // console.log(brandName, ' ', brandCategory)
  if (brandCategory !== null && brandName !== null) {
    uid = hash.substring(0, hash.length);

    isFromWebsite = true;
  } else {
    uid = hash.substring(6, hash.length);
  }

  const projectId = hash.substring(0, 6);

  useEffect(() => {
    checkUID(uid, (response) => {
      if (response) {
        if (state === null) {
          if (isFromWebsite) {
            setState(States.WAITING);
            getProjectDetailsByPath(brandName, brandCategory, (res) => {
              if (res.status === States.FAILURE) {
                setState(States.FAILURE);
              } else {
                if (res.isOnHold === true) {
                  setState(States.ONHOLD);
                } else {
                  setProjectData({
                    ...projectData,
                    projectName: res.projectName,
                    leadCategory: res.leadCategory,
                    projectDetails: res.projectDetails,
                    bannerUrl: res.bannerURL,
                  });
                  setState(States.INIT);
                }
              }
            });
          } else {
            setState(States.WAITING);
            getProjectDetails(projectId, function (res) {
              if (res.status === States.FAILURE) {
                setState(States.FAILURE);
              } else {
                if (res.isOnHold === true) {
                  setState(States.ONHOLD);
                } else {
                  setProjectData({
                    ...projectData,
                    projectName: res.projectName,
                    leadCategory: res.leadCategory,
                    projectDetails: res.projectDetails,
                    bannerUrl: res.bannerURL,
                  });

                  setState(States.INIT);
                }
              }
            });
          }
        }
      } else {
        setState(States.INVALIDURL);
      }
    });
  }, [
    state,
    brandName,
    brandCategory,
    isFromWebsite,
    uid,
    projectData,
    projectId,
  ]);

  function submitLead(lead) {
    setState(States.WAITING);
    setLead(lead);
    postLead(lead, function (res) {
      if (res.status === States.SUCCESS) {
        if (res.submitted === true) {
          setLink(res.link);
          setState(States.SUBMITTED);
        } else if (res.isLeadExist === true) {
          setState(States.EXISTS);
        } else {
          setState(States.FAILURE);
        }
      } else {
        setState(States.FAILURE);
      }
    });
  }

  if (state === States.WAITING || state === null) {
    return <Spinner />;
  } else if (state === States.ONHOLD) {
    return OnHoldUI();
  } else if (state === States.INIT) {
    if (LEAD_CATEGORIES.includes(projectData.leadCategory)) {
      return (
        <LeadForm
          uid={uid}
          projectData={projectData}
          submitLead={(lead) => {
            lead.isFromWebsite = isFromWebsite;
            submitLead(lead);
          }}
        />
      );
    }
  } else if (state === States.EXISTS) {
    return (
      <Alert
        state={state}
        onStateChange={(alertState) => {
          setState(alertState);
        }}
      />
    );
  } else if (state === States.ERROR || state === States.INVALIDURL) {
    return <InvalidUrl />;
  } else if (state === States.SUBMITTED) {
    window.open(link, "_self");
  } else if (state === States.PROCEED) {
    lead.proceed = true;
    submitLead(lead);
  } else if (state === States.FAILURE) {
  } else if (state === States.ALLCC) {
    setCustlead(lead);
    navigation("AllAvailableCC", {
      lead,
    });
  }
}

export default App;
