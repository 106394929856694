import React from 'react'
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Components.css';


export default function Alert(props) {

    function Cancel(e) {
        e.preventDefault();
        console.log("Cancel")
        props.onStateChange('canceled');
    }

    function Proceed(e) {
        e.preventDefault();
        console.log("Proceed")
        props.onStateChange('proceed');
    }

    return (

        <div>

            <div className="alertDiv" role="alert">
                <div className="d-flex my-3 flex-row errorAlert">
                 
                    <p style={{ fontFamily: "monospace", marginLeft: "10px" }}>We have found this lead in our database.</p>
                </div>
                <p style={{ fontFamily: "monospace", marginLeft: "10px" }}>do you want to update</p>
                <div className="d-flex my-3 flex-row-reverse">
                    <Button variant="warning" className='AlertBtnCancel' onClick={Cancel}>Cancel</Button>
                    <Button className='AlertBtnProceed' variant="primary" onClick={Proceed}>Proceed</Button>
                </div>


            </div>


        </div>
    )
}
