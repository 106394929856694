import React, { useState, useEffect } from "react";
import "../css/landingpage.css";

function ProjectDetails(props) {
  const details = props.projectDetails;
  const [detailsArray, setDetailsArray] = useState([]);

  useEffect(() => {
    if (details) {
      const list = details.split(/(?:\\n\\n|\n\n)/).filter(Boolean);
      setDetailsArray(list);
    }
  }, [details]);

  return (
   
    <div className="card-body p-md-4">
    <div className="px-md-2">
      <p>Features & Benefits</p>
      {detailsArray.map((det, index) => (
        <li key={index}>
          <span className="text">
          {det.replace(/&nbsp;/g, " ").replace(/\u00A0/g, " ")}
          </span>
        </li>
      ))}
    </div>
  </div>                              
  );
}

export default ProjectDetails;
