import React from 'react'
import errorImg from '../images/errorcloud.png'
import '../css/style.css';

export default function InvalidUrl() {
  return (
    <div className='invalidUrl'>
      <div className='invalid-img'>
        <img src={errorImg} alt="" className="errImg" />
        </div>
        <div className='invalid-msg'>
        <p className="invalidurlmessage">Oops! we could not found this URL Valid..</p>
        </div>
    </div>
  )
}
