import React from 'react'
import ReactWhatsapp from "react-whatsapp";
import { Linking } from "react-native";
import whatsapp from "../images/whatsapp_icon.png";
import call from "../images/call_2.png";
import "../css/website.css";

function Contact({agentMobile}) {
  return (
    <div className="contact-box">
    <ReactWhatsapp
      className="openwatsapp"
      number={agentMobile}
      message="Hi"
    >
      <img src={whatsapp} alt="" id="whatsapp" />
    </ReactWhatsapp>
    <img src={call} alt="" id="call" onClick={openDialer} />
  </div>
  )

   function openDialer() {
    Linking.openURL(`tel:${agentMobile}`);
  }
}

export default Contact
