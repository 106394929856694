import React from "react";
import { importAllImages } from "../Helper";
import "../css/Partners.css";

function Partners() {
  const images = importAllImages(
    require.context("../images", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <>
      <div className="partner-container">
        <h4 className="h4-partner-title">Our Partners</h4>
        
        <div className="partner-grid">

          <div className="partner-item custom-size">
            <img src={images["angel_new_logo.png"]}  alt=""/>
          </div>

          <div className="partner-item">
            <img src={images["paytmmoneylogo.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["tide_logo.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["mstock.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["iifl_logo_new.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["edelwies2.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["samcologo.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["kotak.png"]} alt="" />
          </div>

          <div className="partner-item custom-size">
            <img src={images["upwardsloan.png"]} alt="" />
          </div>

          <div className="partner-item">
            <img src={images["bajaj_logo_new.png"]} alt="" />
          </div>

        </div>
        
      </div>
    </>
  );
}

export default Partners;
