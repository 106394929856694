import React from "react";
import "../css/Shimmer.css";

function TrendingProjectShimmer() {
  return (
    <>
      <div className="shimmer-container">
        <div className="skeleton-trending-project" id="tp-shimmer-1"></div>
        <div className="skeleton-trending-project" id="tp-shimmer-2"></div>
        <div className="skeleton-trending-project" id="tp-shimmer-3" ></div>
        <div className="skeleton-trending-project" id="tp-shimmer-4"></div>
      </div>
    </>
  );
}

export default TrendingProjectShimmer;
