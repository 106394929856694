import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAgentNameAndMobile, checkUID } from "../Connection";
import Certifications from "./Certifications";
import BannerSlider from "./BannerSlider";
import Categories from "./Categories";
import Featured from "./Featured";
import Enquiry from "./Enquiry";
import Benefits from "./Benefits";
import WebsiteFooter from "./WebsiteFooter";
import TrendingProjectsSBannerlider from "./TrendingProjectsSBannerlider";
import Partners from "./Partners";
import InvalidUrl from "./InvalidUrl";
import Spinner from "./Spinner";
import Contact from "./Contact";
import * as States from "./States";
import "../css/slick.css";
import "../css/slick-theme.css";
import "../css/website.css";

export default function Website() {
  const [searchParams] = useSearchParams();
  const [agent, setAgent] = useState({ name: "", mobile: "" });
  const uid = searchParams.get("uid");
  const [state, setState] = useState(null);

  useEffect(() => {
      checkUID(uid, (response) => {
        if (!response) {
          setState(States.INVALIDURL);
        } else {
          if (agent.name === "") {
            getAgentNameAndMobile(uid, (res) => {
              if (res.status === States.FAILURE) {
                setState(States.INVALIDURL);
              } else {
                setAgent({ name: res.name, mobile: res.mobile });
              }
            });

          }
          setState(States.SUCCESS);
        }
      });
    }
 
  , [agent.name, uid]);

 if (state === States.INVALIDURL) {
    return (
      <>
        <InvalidUrl />
      </>
    );
  } else if(state === States.SUCCESS) {
    return (
      <>
        <div className="container-body">
          <div className="cs-container">
            <div className="certificate">
              <Certifications agentName={agent.name.split(" ")[0]} />
            </div>

            <div className="Slider">
              <BannerSlider uid={uid} />
            </div>
          </div>

          <Categories uid={uid} />

          <TrendingProjectsSBannerlider uid={uid} />

          <Featured />

          <Enquiry />

          <Benefits />

          <Partners />

          <WebsiteFooter />
          <Contact agentMobile={agent.mobile} />
        </div>
      </>
    );
  }
  else{
    return(
      <>
      <Spinner/>
      </>
    )
  }
}
