import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'
// import { importAll } from './Helper'
import instantloan from "../images/instant-loan.png"
import personalloan from "../images/personalloan.png"
import businessloan from "../images/businessLoan.png"
import "../css/categories.css";

export default function LoanCategory() {
    const [params] = useSearchParams();
    const uid = params.get("uid");



   //  const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));
    return (
        <div className="agentinfobox-container-loans ">
              <div className="category-header">
        <div className="category-title">
          <h4>Loan Categories</h4>
        </div>
      </div>

            {/* Categories */}
            <div className="grid">

            <Link className='linkClass' to={{
                    pathname: '/AllProducts',
                    search: `?category=Instant loan&uid=${uid}`,
                }}>
                    <div className="grid-item">
                        <img src={instantloan} alt="" className="categoryImage" />
                        <p className="categoryName">Instant Loan</p>
                    </div>
                </Link>

                <Link className='linkClass' to={{
                    pathname: '/AllProducts',
                    search: `?category=Personal loan&uid=${uid}`,
                }}>
                    <div className="grid-item">
                        <img src={personalloan} alt="" className="categoryImage" />
                        <p className="categoryName">Personal Loan</p>
                    </div>
                </Link>

                <Link className='linkClass' to={{
                    pathname: '/AllProducts',
                    search: `?category=Business loan&uid=${uid}`,
                }}>
                    <div className="grid-item">
                        <img src={businessloan} alt="" className="categoryImage" />
                        <p className="categoryName">Business loan</p>
                    </div>
                </Link>
            </div>
        </div>
    )

  
}
