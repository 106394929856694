import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { submitEnquiry } from "../Connection";
import * as States from "./States";
import * as Validation from "./Validations";
import Spinner from "./Spinner";
import Error from "./Error";
import MyModal from "./MyModal";
import query from "../images/query.png";
import "../css/enquiry.css";

export default function Enquiry() {
  const [searchParams] = useSearchParams();
  const agentuid = searchParams.get("uid");

  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    category: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    category: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedCategoryColor, setSelectedCategoryColor] = useState("gray");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showModal, setShowmodal] = useState(false);
  const [showError, setShowError] = useState(false);
  const closeModal = () => {
    setShowmodal(false);
  };
  const closeError = () => {
    setShowError(false);
  };

  function onInputChange(e) {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === "category") {
      setSelectedCategoryColor("black");
    }

    if (value !== "") {
      setErrors({ ...errors, [name]: "" });
    }
  }

  const submitDetails = () => {
    setIsFormSubmitted(true);

    const fullNameValidation = Validation.validateFullName(data.name);
    const emailValidation = Validation.validateEmail(data.email);
    const mobileNumberValidation = Validation.validateMobileNumber(data.mobile);
    const categoryValidation = Validation.validateCategory(data.category);

    setErrors({
      name: fullNameValidation,
      mobile: mobileNumberValidation,
      email: emailValidation,
      category: categoryValidation,
    });

    if (
      !fullNameValidation &&
      !emailValidation &&
      !mobileNumberValidation &&
      !categoryValidation
    ) {
      const enquiry = {
        uid: agentuid,
        name: data.name,
        mobile: data.mobile,
        email: data.email,
        category: data.category,
      };
      setLoading(true);

      submitEnquiry(enquiry, (res) => {
        if (res.status === States.SUCCESS) {
          setData({
            name: "",
            mobile: "",
            email: "",
            category: "",
          });
          setSelectedCategoryColor("gray");
          setLoading(false);
          setShowmodal(true);
        } else {
          setLoading(false);
          setShowError(true);
        }
      });
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {showError && <Error closeError={closeError} />}
      {showModal && <MyModal closeModal={closeModal} />}
      <div className="enquiry-wrapper">
        <div className="enquiry-image">
          <img src={query} alt="query" />
        </div>
        <div className="enquiry-box">
          {" "}
          <div className="enquiry-tag">
            <h5 className="h5-enquiry">Do you have any queries?</h5>
            <p className="p-enquiry">
            For your queries please fill up the form given below. We shall reach out to you at the earliest
            </p>
          </div>
          <div className="enquiry-input-box">
            <div className="enquiry-input">
              <input
                placeholder="Name"
                id="bottomInput"
                type="text"
                required=""
                name="name"
                maxLength={25}
                value={data.name}
                onChange={onInputChange}
              />
              {isFormSubmitted && errors.name && (
                <div className="error-message" id="namingerror-web">
                  <span className="error-star">*</span> {errors.name}
                </div>
              )}
            </div>
            <div className="enquiry-input">
              <input
                placeholder="Phone"
                id="mobileInput"
                type="tel"
                maxLength="10"
                required=""
                name="mobile"
                className="tel"
                minLength="10"
                value={data.mobile}
                onChange={onInputChange}
              />
              {isFormSubmitted && errors.mobile && (
                <div className="error-message">
                  <span className="error-star">*</span> {errors.mobile}
                </div>
              )}
            </div>
            <div className="enquiry-input">
              <input
                placeholder="Email"
                id="bottomInput"
                type="email"
                required=""
                name="email"
                value={data.email}
                onChange={onInputChange}
              />
              {isFormSubmitted && errors.email && (
                <div className="error-message">
                  <span className="error-star">*</span> {errors.email}
                </div>
              )}
            </div>
            <div className="enquiry-input">
              <select
                // id="bottomInput"
                id="selectedCategory"
                required=""
                onChange={onInputChange}
                name="category"
                className="category-box"
                value={data.category}
                style={{ color: selectedCategoryColor }}
              >
                <option value="" id="category" disabled="disabled">
                  {" "}
                  Select Category{" "}
                </option>

                <option value="Credit Card"> Credit Card</option>
                <option value="Loan"> Loan </option>
                <option value="Savings Account "> Savings Account </option>
                <option value="Demat Account">Demat Account</option>
                <option value="UPI">UPI</option>
                <option value="BNPL">BNPL</option>
              </select>
              {isFormSubmitted && errors.category && (
                <div className="error-message">
                  <span className="error-star">*</span> {errors.category}
                </div>
              )}
            </div>
          </div>
          <div className="enquiry-input-button">
            <button id="enquiry-button" onClick={submitDetails}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
