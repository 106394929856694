import React, { useEffect } from "react";
import { getPopularProjects } from "../Connection";
import TrendingProjectShimmer from "./TrendingProjectShimmer";
import Slider from "react-slick";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../css/slick.css";
import "../css/slick-theme.css";
import "../css/website.css";
import "../css/trendingproject.css";

function TrendingProjectsSBannerlider(props) {
  const uid = props.uid;
  const [trendingBrands, setTrendingBrands] = useState([]);

  useEffect(() => {
    getPopularProjects((result) => {
      setTrendingBrands(result.result);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  const responsiveSettings = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        speed: 700,
        autoplaySpeed: 5000,
        cssEase: "linear",
      },
    },
  ];

  if (trendingBrands.length === 0) {
    return (
      <>
        <div className="tp-header">
        <div className="tp-title">
          <h4>Trending Brands</h4>
        </div>
      </div>
      <TrendingProjectShimmer/>
      </>
    );
  }

  return (
    <>
      <div className="tp-header">
        <div className="tp-title">
          <h4>Trending Brands</h4>
        </div>
      </div>

      <div className="trendig-project-wrapper">
        <Slider {...settings} responsive={responsiveSettings}>
          {trendingBrands.map((brands) => (
            <div key={brands.name} className="trendingProject-box">
              <Link
                to={{
                  pathname: "/",
                  search:
                    "?category=" +
                    brands.category +
                    "&project=" +
                    brands.name +
                    "&uid=" +
                    uid,
                }}
                id="trending_link"
              >
                <div
                  className="trendingProject-inner"
                  style={{ backgroundColor: brands.bgcolor }}
                >
                  <div className="trendingProject-details">
                    <p id="brandName">{brands.name}</p>
                    <p id="brandCategory">{brands.category}</p>
                  </div>
                  <div className="trendingProject-Image">
                    <img src={brands.logo} alt="" />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default TrendingProjectsSBannerlider;
