import React from 'react'
import successImg from '../images/ok_new.png'

export default function PayUSuccess() {
    console.log("===PayUSuccess====")
  return (
    <div className="payudiv" style={{ position: 'absolute',
    width: '90%',
    height: '20%',
    left: '10%',
    top: '45%',
   }}>
        <img src={successImg} alt="" id="payulogo" style={{position: 'absolute', left: '20%', width: '20%', height: 'auto', }}/>
        <p className="tagline" id="taglineId" style={{ position: 'absolute', top: '60%', height: '14px', right: '20%'}}>
            Payment Successfull! Please wait we are redirecting you to ARYO App, Do not refresh</p>
    </div>
  )
}
