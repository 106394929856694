import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchProjectLink } from "../Connection";
import InvalidUrl from "./InvalidUrl";
import Loader from "./Loader";
import { OnHoldUI } from "./OnHoldUI";

export default function Share() {
  const [params] = useSearchParams();

  const [status, setStatus] = useState(null);
  const lid = params.get("id");
  console.log("lead id ", lid);

  useEffect(() => {
    console.log(lid);
    if (lid != null) {
      fetchProjectLink(lid, function (data) {
        if (data.status === 200 && data.data.status === "success") {
          if (data.data.onHold === false) {
            window.open(data.data.link, "_self");
          } else {
            setStatus("onHold");
          }
        } else {
          setStatus("failure");
        }
      });
    }
  }, [lid, status]);

  if (status === "onHold") {
    return (
      <div>
        <OnHoldUI />
      </div>
    );
  } else if (status === "failure") {
    return (
      <div>
        <InvalidUrl />
      </div>
    );
  } else {
    return (
      <div>
        <Loader />
      </div>
    );
  }
}
