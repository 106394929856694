import React from "react";

function ProductsShimmer() {
  return (
    <>
      
       
        <div className="products-shimmer">
        
        </div>
     
    </>
  );
}

export default ProductsShimmer;
