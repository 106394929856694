import React from "react";
import "../css/Shimmer.css";

function BannerSliderShimmer() {
  return (
    <div className="skeleton-slider">
    </div>
  );
}

export default BannerSliderShimmer;