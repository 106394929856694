// constants.js

export const LEAD_CATEGORIES = [
   "App Download",
   "Demat Account",
   "Savings Account",
   "Business Account",
   "Credit Card",
   "Instant loan",
   "Personal loan",
   "Business loan",
   "Investment Account",
   "BNPL",
   "Crypto",
   "IPL",
   "UPI" ,
   "Current Account",
   "Subscription",
   "Travel Card",
 ];
 