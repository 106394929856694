
import { Fragment } from 'react'
import spinner from '../spinner.gif'
import '../css/style.css';
const Loader = () =>{
    return (
        <div className='Spinner' style={{ position: 'absolute',
        width: '20%',
        height: '20%',
        left: '45%',
        top: '45%',
       }}>
            <Fragment>
            <img className= "blueSpinner" alt='' src={spinner} />
            </Fragment>
        </div>
    )
}

export default Loader;