import React from "react";
import { Link } from "react-router-dom";
import "../css/website.css";

function WebsiteFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="conditions">
        <div className="terms-policy">
          <p id="copyright">
            All Rights Reserved |{" "}
            <span>
              <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
            </span>{" "}
            <span id="companyname">@ARYO&nbsp;</span>
            <span>{currentYear}&nbsp;</span>
          </p>

          <p id="terms">
            <Link className="termsLink" to="https://aryo.co.in/terms">Terms of service</Link>{" "}
            &nbsp; &nbsp; &nbsp;{" "}
            <Link className="termsLink" to="https://aryo.co.in/privacy">Privacy Policy</Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default WebsiteFooter;
