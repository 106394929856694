import axios from "axios";
import { useQuery } from "react-query";

// const URL = 'http://34.93.23.207:8080/';
const URL = "https://aryoconnect.in/";
// const URL = "http://192.168.1.11:8080/";
// const URL = "http://192.168.253.131:8081/";

export async function postLead(lead, callBack) {
  const result = await axios.post(URL + "submitLead", lead);
  callBack(result.data);
}

export const fetchProjectDetail = (hash) => {
  return axios.get(URL + "projectDetails", { params: { hash: hash } });
};

export const fetchProjectLink = async (id, callBack) => {
  console.log("inside lead")
  const result = await axios.get(URL + "getLeadLink", { params: { lid: id } });
  callBack(result);
};

export const getBanners = async (callback) => {
  const result = await axios.get(URL + "getProductBannersForTeamsWeb");
  callback(result.data);
};

export const getFeaturesProjects = async (callback) => {
  const result = await axios.get(URL + "getEcomProjects");
  callback(result.data);
};

// export const checkPinCodeAvailibility = async (pc, callBack) => {
//   const result = await axios.get(URL + "isAvaibleOnPinCode", {
//     params: { pincode: pc },
//   });
//   callBack(result.data);
// };

export const getAllProjectsByCategory = async (cat, callBack) => {
  const result = await axios.get(URL + "getAllProjectsByCategory", {
    params: { category: cat },
  });
  callBack(result.data);
};

export const getLiveProjects = async (cat, callback) => {
  const result = await axios.get(URL + "getLiveProjects", {
    params: { category: cat },
  });
  callback(result.data);
};

export const getPopularProjects = async (callBack) => {
  const result = await axios.get(URL + "getPopularProjects");
  callBack(result.data);
};

export const getAgentNameAndMobile = async (id, callback) => {
  const result = await axios.get(URL + "getOwnerDetails", {
    params: { uid: id },
  });
  callback(result.data);
};

export async function getProjectDetailsByPath(name, category, callback) {
  const result = await axios.get(URL + "projectDetailsByName", {
    params: { projectName: name, category: category },
  });
  callback(result.data);
}
export async function getProjectDetails(hash, callBack) {
  const result = await axios.get(URL + "projectDetails", {
    params: { hash: hash },
  });
  callBack(result.data);
}

export async function getLegacyProjectDetails(hash, callBack) {
  const result = await axios.get(URL + "legacyProjectDetails", {
    params: { hash: hash },
  });
  callBack(result.data);
}

export async function isOnHold(projectName, category, callback) {
  const result = await axios.post(URL + "isOnHold", {
    projectName: projectName,
    leadCategory: category,
  });

  callback(result.data.isOnHold);
}

// website
export async function submitEnquiry(enquiry, callBack) {
  const result = await axios.post(URL + "submitEnquiry", {
    Data: enquiry,
  });
  callBack(result.data);
}

// Checking UID

export async function checkUID(uid, callBack) {
  const result = await axios.get(URL + "isUidExist", {
    params: {
      uid: uid,
    },
  });
  callBack(result.data);
}

export async function checkPincode(pincode , callBack) {
  const result = await axios.get(URL + 'checkPincode' , {
    params : {
      pincode : pincode ,
    } ,
  });
  callBack(result.data);
}
