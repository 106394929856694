export const WAITING="waiting";
// export const UIDLENGTH=28;
export const ONHOLD="onHold";
export const INIT="init";
export const SUCCESS="success";
export const EXISTS="exist";
export const SUBMITTED="submitted";
export const FAILURE="failure";
export const ALLCC="ALLCC";
export const INVALIDURL="InvalidUrl";
export const ERROR="Error";
export const PROCEED="proceed";
export const INVALID_PINCODE="Invalid Pincode";