import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { getBanners } from "../Connection.js";
import BannerSliderShimmer from "./BannerSliderShimmer.js";
import "../css/slick.css";
import "../css/slick-theme.css";

function BannerSlider(props) {
  const uid = props.uid;
  const [banners, setBanners] = useState([{ name: "", url: "" }]);

  useEffect(() => {
    getBanners((res) => {
      let listBanners = [];
      res.map((k, v) => {
        if (k != null) {
          listBanners.push({
            name: Object.keys(k)[0],
            url: Object.values(k)[0],
          });
        }
      });
      setBanners(listBanners);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  if (banners.length === 1) {
    return (
      <>
        <BannerSliderShimmer />
      </>
    );
  } else {
    return (
      <>
        <Slider {...settings}>
          {banners.map((entry) => {
            let name = "";
            if (entry.name.includes("ProjectMain")) {
              return (
                <Link
                  key={name}
                  to={{
                    pathname: "/",
                    search:
                      "?category=" +
                      entry.name.split("_")[1] +
                      "&project=" +
                      entry.name.split("_")[2] +
                      "&uid=" +
                      uid,
                  }}
                  className="link-Slider"
                >
                  <img src={entry.url} />
                </Link>
              );
            } else if (entry.name.includes("AllProjects")) {
              return (
                <Link
                  key={entry.name}
                  to={{
                    pathname: "/AllProducts",
                    search:
                      "?category=" + entry.name.split("_")[1] + "&uid=" + uid,
                  }}
                  className="link-Slider"
                >
                  <img src={entry.url} />
                </Link>
              );
            }
          })}
        </Slider>
      </>
    );
  }
}

export default BannerSlider;
