import React from "react";
import "../css/Certificate.css";
import "../css/website.css";

function Certifications({ agentName }) {
  return (
    <div className="certificate-wrapper">
      <div className="certificate-info">
        <div className="agent-mobile-view">
          <p>
            <span className="agent-info">Hello! </span>{" "}
          </p>
          <p>
            <span className="agent-info">
              I am{" "}
              <span id="agentname">
                {agentName.charAt(0).toUpperCase() +
                  agentName.slice(1).toLowerCase()}
              </span>
              ,
            </span>
          </p>
          <p>
            {" "}
            <span className="agent-info">your</span>{" "}
            <span id="agentdesignation">Financial Advisor</span>
          </p>
        </div>
     
        <div className="agent-message">
          <p>
            I have been helping customers in getting suitable financial products
            like   Cards, Loans, Savings and Demat Accounts etc.  
          </p>
        </div>
        <div className="explore">
          <p>
            Explore the website to
            <span id="explore-products">
              {" "}
              experience new age financial products!
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Certifications;
